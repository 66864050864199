<template>
    <div v-if="post" class="post">
      <h1>{{ post.title }}</h1>
      <p>{{ post.date }}</p>
      <p>categories: {{ post.categories }}</p>
      <p>tags: {{ post.tags }} </p>
      <div v-html="post.content"></div>
    </div>
  </template>
  
  <script>
  import postService from '@/services/postService';
    /* eslint-disable */
  export default {
    name: 'Post',
    data() {
      return {
        post: null,
      };
    },
    async mounted() {
      const id = this.$route.params.id;
      this.post = await postService.getPost(id);
    },
  };
  </script>
  
<style scoped>
.post {
  padding: 20px; /* Add padding for better spacing */
  max-width: 800px; /* Limit max width for readability */
  margin: auto; /* Center the post */
  background-color: #fff; /* White background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 80%;
}

h1 {
  font-size: 2em; /* Adjust heading size */
  margin-bottom: 10px; /* Space below the heading */
}

p {
  margin: 5px 0; /* Space between paragraphs */
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5em; /* Smaller heading size for mobile */
  }
  .post {
    padding: 15px; /* Reduce padding on mobile */
  }
}

image{
  max-width: 80%;
}
</style>