<template>
    <div class="search-bar">
      <input 
        v-model="searchQuery" 
        @input="$emit('search', searchQuery)"
        placeholder="Search..."
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SearchBar',
    data() {
      return {
        searchQuery: '',
      };
    },
  };
  </script>