
<template>
    <div class="pagination">
      <button @click="$emit('prev')" :disabled="currentPage === 1">上一页</button>
      <span>第 {{ currentPage }} 页</span>
      <button @click="$emit('next')" :disabled="!hasMore">下一页</button>
    </div>
  </template>
  
  <script>
   /* eslint-disable */
  export default {
    name: 'Pagination',
    props: {
      currentPage: Number,
      hasMore: Boolean,
    },
  };
  </script>