<template>
    <pre v-if="sitemapXml">{{ sitemapXml }}</pre> <!-- 显示 XML 内容 -->
</template>

<script>
import api from '@/services/api'; // 导入 API 服务

export default {
  name: 'SitemapDetail',
  data() {
    return {
      sitemapXml: '', // 存储站点地图的 XML 内容
    };
  },
  async created() {
    const page = this.$route.params.url; // 从路由参数获取页面编号
    const sitemapUrl = `/sitemap-${page}`; // 构建正确的请求路径
    await this.fetchSitemap(sitemapUrl); // 获取对应的站点地图
  },
  methods: {
    async fetchSitemap(url) {
      try {
        const response = await api.get(url, { headers: { 'Accept': 'application/xml' } }); // 指定请求头为 XML
        this.sitemapXml = response.data; // 将 XML 内容存储到数据属性中
      } catch (error) {
        console.error('获取站点地图失败:', error);
      }
    },
  },
};
</script>

<style scoped>
/* 添加样式 */
h1 {
  font-size: 2em;
}
pre {
  white-space: pre-wrap; /* 保持格式 */
  word-wrap: break-word; /* 处理长单词 */
  background-color: #f8f8f8; /* 背景色 */
  padding: 10px; /* 内边距 */
  border: 1px solid #ddd; /* 边框 */
}
</style>