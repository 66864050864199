<template>
  <div class="post-list">
    <div v-for="post in posts" :key="post.id" class="post-item">
      <router-link :to="`/post/${post.id}`">{{ post.title }}</router-link>
      <p v-html="post.content.substring(0, 800) + (post.content.length > 800 ? '...' : '')"></p> <!-- 使用 v-html 显示 HTML 内容 -->
      <p>{{ post.date }}</p>
      <p>categories: {{ post.categories }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostList',
  props: {
    posts: Array,
  },
};
</script>

<style scoped>
/* 添加样式 */
.post-list {
  margin: 20px 0;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
}

.post-item {
  margin-bottom: 15px;
  width: 100%; /* Full width for better responsiveness */
  max-width: 600px; /* Limit max width for larger screens */
  padding: 15px; /* Add padding for better spacing */
  border: 1px solid #ddd; /* Add a border for separation */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: #fff; /* White background for contrast */
}

.post-item h2 {
  font-size: 1.5em; /* Adjust title size */
  margin: 0 0 10px; /* Space below title */
}

.post-item p {
  margin: 5px 0; /* Space between paragraphs */
}

@media (max-width: 600px) {
  .post-item {
    padding: 10px; /* Reduce padding on mobile */
  }
  .post-item h2 {
    font-size: 1.2em; /* Smaller title size for mobile */
  }
}
</style>