import api from './api';

export default {
  async searchPosts(query, page = 1, hitsPerPage = 10) {
    const response = await api.get('/search', { params: { query, page, hitsPerPage } });
    return response.data; // Ensure this returns hits and totalPages
  },
  
  async getPost(id) {
    const response = await api.get(`/posts/${id}`);
    return response.data;
  },
};